<template>
  <form @submit.prevent="formSubmit(handleSubmit)" novalidate class="contact-form">
    <input type="hidden" name="subject" v-model="form.subject"/>
    <v-layout row wrap>
      <v-flex xs12>
        <v-text-field
          v-model="form.sender_email"
          @input="$v.form.sender_email.$touch()"
          :error-messages="errorMessages('sender_email', 'Email')"
          name="sender_email"
          type="email"
          required
          aria-required="true"
          :label="$t('Contact.Form.Email')"
          solo>
        </v-text-field>

        <v-textarea
          v-model="form.message_body"
          @input="$v.form.message_body.$touch()"
          :error-messages="errorMessages('message_body', 'Message')"
          required
          aria-required="true"
          solo
          name="message_body"
          :label="$t('Contact.Form.Message')">
        </v-textarea>
        
        <v-btn 
          class="btn-extra-wide"
          type="submit" 
          :loading="loading" 
          color="accent" 
          depressed
          :disabled="!!success">
          {{ 
            (success)
              ? 'Good job'
              : $t('Contact.Form.Submit')
          }}
        </v-btn>
        <recaptcha-policy />
      </v-flex>
    </v-layout>

    <div class="form__alerts">
      <transition name="small-slide" mode="out-in">
        <alert v-if="error" type="error">
          {{ error }}
          Please try again.
        </alert>
      </transition>
      <transition name="small-slide" mode="out-in">
        <alert v-if="success" type="success">{{ success }}</alert>
      </transition>
    </div>
  </form>
</template>

<script>
import emailApi from '@/api/email'
import formMixin from '@/mixins/formMixin'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'contact-form',

  mixins: [formMixin],

  data() {
    return {
      form: {
        subject: 'Submission from The West is Burning Connect Form',
        sender_email: '',
        message_body: ''
      }
    }
  },

  methods: {
    ...mapActions(['generalEvent']),
    async handleSubmit() {
      this.error = null
      this.loading = true

      if (this.possibleRobot) {
        this.form.subject = `[POSSIBLE SPAM] ${this.form.subject}`
      }

      try {
        await emailApi.post({
          replyTo: this.form.sender_email,
          subject: this.form.subject,
          body: this.form.message_body
        })
        this.loading = false

        this.success = `Success. We'll be in touch shortly!`
        this.resetForm()
        this.generalEvent({
          category: 'forms',
          action: 'submit-success',
          label: 'contact'
        })
      } catch (e) {
        this.success = false
        this.error = `Uh oh. We've encountered an issue on our end.`
        throw e
      }
    }
  },

  validations: {
    form: {
      sender_email: { required, email },
      message_body: { required }
    }
  }
}
</script>
