<template>
  <view-wrapper header-text="Connect with us" class="contact">
    <p>The team behind <strong>THE WEST IS BURNING</strong> are eager to hear what you have to say. Send us a note and we'll get back to you right away.</p>

    <contact-form></contact-form>
  </view-wrapper>
</template>

<script>
import ContactForm from '@/components/_forms/ContactForm/ContactForm'

export default {
  name: 'contact',

  components: {
    ContactForm
  }
}
</script>
