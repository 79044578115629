import axios from 'axios'
import { encodeAppName } from '@/utils/general'

const emailApi = {
  async post({ name, replyTo, subject, body }) {
    if (
      !replyTo ||
      !subject ||
      !body
    ) {
      throw new Error('[emailApi.post] missing required params')
    }

    const bodyStripped = body.replace(/(<([^>]+)>)/gi, '')
    const message = `
      <strong>From: </strong> ${replyTo}
      <br>
      <br>
      <strong>Message: </strong> ${bodyStripped}
      <br>
      <br>
      <br>
      (you can reply directly to this email to respond to requester)
    `

    return axios.post(`${process.env.VUE_APP_CALS_SERVICES}/ses?app=westisburning`, {
      replyTo,
      replyToName: name,
      destination: process.env.VUE_APP_CONTACT_DEST || 'CCT-WebDev@arizona.edu',
      subject,
      message
    }, {
      headers: {
        authorization: encodeAppName('westisburning')
      }
    })
  }
}

export default emailApi
